import { Component, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LuckyCellsService } from '@libs/app.services/landing/lucky-cells.service';
import { LuckyCellsGridCellFiatComponent } from './lucky-cells-grid-cell-fiat/lucky-cells-grid-cell-fiat.component';
import { LuckyCellsGridCellFreeSpinComponent } from './lucky-cells-grid-cell-free-spin/lucky-cells-grid-cell-free-spin.component';
import { LuckyCellsGridCellPointsComponent } from './lucky-cells-grid-cell-points/lucky-cells-grid-cell-points.component';
import { LuckyCellsGridCellRechargeComponent } from './lucky-cells-grid-cell-recharge/lucky-cells-grid-cell-recharge.component';

@Component({
  selector: 'app-lucky-cells-grid',
  imports: [
    CommonModule,
    LuckyCellsGridCellFiatComponent,
    LuckyCellsGridCellFreeSpinComponent,
    LuckyCellsGridCellPointsComponent,
    LuckyCellsGridCellRechargeComponent,
  ],
  templateUrl: './lucky-cells-grid.component.html',
  styleUrl: './lucky-cells-grid.component.scss',
})
export class LuckyCellsGridComponent {
  matrix = computed(() => this.luckyCellsService.matrix());
  constructor(private luckyCellsService: LuckyCellsService) {}
}
