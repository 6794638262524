import { Component, effect, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LuckyCellsService } from '@libs/app.services/landing/lucky-cells.service';
import { AUDIO, LuckyCellsState } from '@libs/interfaces';
import { first, tap, timer } from 'rxjs';
import { AudioServiceLanding } from '@libs/app.services/audio.service-landing';
@Component({
  selector: 'app-lucky-cells-action',
  imports: [CommonModule, TranslateModule],
  templateUrl: './lucky-cells-action.component.html',
  styleUrl: './lucky-cells-action.component.scss',
})
export class LuckyCellsActionComponent implements OnDestroy {
  // Observable for randomization animation
  ramdomize$;
  // Observable for adding new game
  add$;

  constructor(
    public service: LuckyCellsService,
    private audioService: AudioServiceLanding,
  ) {
    // Handle audio based on game state changes
    effect(() => {
      if (this.service.state() === LuckyCellsState.FINISHED || this.service.state() === LuckyCellsState.PLAYING) {
        this.audioService.pause(AUDIO.LUCKY_CELLS_APP);
      } else {
        setTimeout(() => this.audioService.play(AUDIO.LUCKY_CELLS_APP, 1, true), 1000);
      }
    });
  }

  /**
   * Clean up audio when component is destroyed
   */
  ngOnDestroy(): void {
    this.audioService.pause(AUDIO.LUCKY_CELLS_APP);
  }

  /**
   * Starts a new game if allowed
   */
  startGame() {
    if (this.service.enabledPlay()) {
      this.service.state.set(LuckyCellsState.PLAYING);
      this.startPlaying();
    }
    if (this.service.state() === LuckyCellsState.READY) {
      this.service.state.set(LuckyCellsState.PLAYING);
      this.startPlaying();
    }
  }

  /**
   * Pauses current game and shows winning cell
   */
  pauseGame() {
    // if (this.service.recentLuckyCellGame()) {
    this.service.state.set(LuckyCellsState.FINISHED);
    setTimeout(() => this.service.showLuckyCell(this.service.recentLuckyCellGame().game.index), 200);
    // }
  }

  /**
   * Resets game state to initial
   */
  restartGame() {
    this.service.resetLuckyCellGame();
  }

  /**
   * Initializes gameplay animation and state
   */
  private startPlaying() {
    this.audioService.pause(AUDIO.LUCKY_CELLS_APP);
    this.service.resetLuckyCellGame();
    this.service.hideMatrix();
    this.add$ = this.service.addLuckyCellGame();
    // Start randomization animation that runs until game state changes
    this.ramdomize$ = timer(1000, 200).pipe(
      tap(() => this.service.randomizeMatrix()),
      first(() => this.service.state() != LuckyCellsState.PLAYING),
    );
  }
}
