<div class="wrapper">
  <icon name="ph:list-bold" color="primary-color" size="24" (click)="toggleList()" />
  <img src="assets/lucky-cells/lucky-cells.png" alt="Lucky Cells" />
  @if (service.enabledPlay()) {
    <div class="remaining">
      <p class="title">{{ 'LUCKY_CELLS.TAB.TITLE' | translate }}</p>
      <p class="time">{{ 'LUCKY_CELLS.TAB.TIME' | translate }}</p>
    </div>
  } @else {
    <div class="remaining">
      <p class="title">{{ 'LUCKY_CELLS.TAB.NEXT_DRAW' | translate }}</p>
      <p class="time">{{ service.remainingTimeToPlay() | millisecondsRemaining }}</p>
    </div>
  }
  <icon name="ph:x" color="primary-color" size="18" (click)="close()" />
</div>
<div class="list" [ngClass]="{ open: showList() }">
  <app-lucky-cells-list [showList]="showList()" />
</div>
