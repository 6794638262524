<div class="wrapper" [ngClass]="{ open: overlay() }">
  <div class="content">
    <app-lucky-cells-tab />
    <div class="info" [ngClass]="{ hide: hideInfo() }">
      <app-lucky-cells-info />
    </div>
    <app-lucky-cells-action />
    <app-lucky-cells-grid />
    <div class="footer">
      <app-lucky-cells-footer />
    </div>
  </div>
</div>
