<div class="wrapper">
  @for (cell of matrix(); track $index) {
    <div class="cell">
      @switch (cell.type) {
        @case ('fiat_5') {
          <app-lucky-cells-grid-cell-fiat [cell]="cell" />
        }
        @case ('points_10') {
          <app-lucky-cells-grid-cell-points [cell]="cell" />
        }
        @case ('points_15') {
          <app-lucky-cells-grid-cell-points [cell]="cell" />
        }
        @case ('points_20') {
          <app-lucky-cells-grid-cell-points [cell]="cell" />
        }
        @case ('bonus_recharge_50') {
          <app-lucky-cells-grid-cell-recharge [cell]="cell" />
        }
        @case ('free_spin_10') {
          <app-lucky-cells-grid-cell-free-spin [cell]="cell" />
        }
      }
    </div>
  }
</div>
