<div class="base" #base cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <div class="wrapper" #wrapper [ngClass]="{ hide: cell().state === 'hide', animation: animation() }"></div>
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="showGame()"
  (overlayOutsideClick)="close()"
>
  <div class="freespin">
    <div class="game-wrapper">
      <app-lucky-cells-game-overlay />
    </div>
    <div class="click-to-play">
      <span>{{ 'LUCKY_CELLS.ACTION.CLICK_TO_PLAY' | translate }}</span>
    </div>
  </div>
</ng-template>
