import { createReducer, on } from '@ngrx/store';
import {
  toggleWallet,
  setAsideLeft,
  setAsideRight,
  toggleAsideLeft,
  toggleAsideRight,
  setOverlayOnRamper,
  setOverlayUploader,
  setOverlayBookmarks,
  setOverlayDraw,
  setOverlaySearcher,
  setLobbyTab,
  setOverlayLuckyCells,
} from './actions';
import { assign } from 'lodash';
import { ISettings } from './model';
import { LOBBY_TABS_NEW } from '@libs/helper/casino/lobby-tabs.helper';
const initialState: Readonly<ISettings> = {
  asideLeft: false,
  asideRight: false,
  overlayProfile: false,
  overlayWallet: false,
  overlayOnRamper: false,
  overlayUploader: false,
  overlayBookmarks: false,
  overlayDraw: false,
  overlaySearcher: false,
  lobbyTabSelected: LOBBY_TABS_NEW[0],
  overlayLuckyCells: false,
};
export const settingsReducer = createReducer(
  initialState,
  on(toggleWallet, state => assign({}, state, { overlayWallet: !state.overlayWallet })),
  on(toggleAsideLeft, state => assign({}, state, { asideLeft: !state.asideLeft })),
  on(toggleAsideRight, state => assign({}, state, { asideRight: !state.asideRight })),
  on(setAsideLeft, (state, { value }) => assign({}, state, { asideLeft: value })),
  on(setAsideRight, (state, { value }) => assign({}, state, { asideRight: value })),
  on(setOverlayOnRamper, (state, { overlayOnRamper }) => assign({}, state, { overlayOnRamper: overlayOnRamper })),
  on(setOverlayUploader, (state, { overlayUploader }) => assign({}, state, { overlayUploader: overlayUploader })),
  on(setOverlayBookmarks, (state, { overlayBookmarks }) => assign({}, state, { overlayBookmarks: overlayBookmarks })),
  on(setOverlayDraw, (state, { overlayDraw }) => assign({}, state, { overlayDraw: overlayDraw })),
  on(setOverlaySearcher, (state, { overlaySearcher }) => assign({}, state, { overlaySearcher: overlaySearcher })),
  on(setLobbyTab, (state, { lobbyTabSelected }) => assign({}, state, { lobbyTabSelected: lobbyTabSelected })),
  on(setOverlayLuckyCells, (state, { overlayLuckyCells }) =>
    assign({}, state, { overlayLuckyCells: overlayLuckyCells }),
  ),
);
