import { CommonModule } from '@angular/common';
import { Component, computed, HostListener, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigService } from '@libs/app.services/landing/config.service';
import { delay, Observable, tap } from 'rxjs';
import { TidioButtonComponent } from '@libs/stand-alone-components/commons/tidio-button/tidio-button.component';
import { StoreSettingService } from '@libs/app.services/store/store-setting.service';
import { RoutesService } from '@libs/app.services/landing/routes.service';
import { Router, RouterModule } from '@angular/router';
import { ToastComponent } from '@libs/stand-alone-components/commons/toast/toast.component';
import { ModalComponent } from '@libs/stand-alone-components/commons/modal/modal.component';
import { RouterLoaderComponent } from '@libs/stand-alone-components/commons/router-loader/router-loader.component';
import { BoxesSpinnerComponent } from '@libs/stand-alone-components/commons/boxes-spinner/boxes-spinner.component';
import { DeviceService } from '@libs/app.services/device.service';
import { TelegramService } from '@libs/app.services/account/telegram.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { LuckyCellsComponent } from './components/layout/lucky-cells/lucky-cells.component';
import { AuthService } from '@libs/app.services/landing/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    CommonModule,
    TranslateModule,
    TidioButtonComponent,
    ToastComponent,
    RouterLoaderComponent,
    RouterModule,
    ModalComponent,
    BoxesSpinnerComponent,
    LuckyCellsComponent,
  ],
})
export class AppComponent {
  path = signal<string>(window.location.pathname);
  config = toSignal(this.configService.init().pipe(tap(() => this.goLstPath())));
  selectAsideRight$: Observable<boolean> = this.settingService.selectAsideRight();
  selectAsideLeft$: Observable<boolean> = this.settingService.selectAsideLeft();
  selectOverlayLuckyCells$: Observable<boolean> = this.settingService.selectOverlayLuckyCells().pipe(delay(300));
  logged = computed(() => this.authService.logged());
  chatAvailable = computed(
    () =>
      (this.routesService.route() == '/' ||
        this.routesService.route() == '/app' ||
        this.routesService.route() == '/casino') &&
      !this.telegramService.telegramMiniApp(),
  );
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const innerWidth = window.innerWidth;
    this.deviceService.displayWidth.set(innerWidth);
    document.documentElement.style.setProperty('--display-w', `${innerWidth}px`);
  }
  constructor(
    private configService: ConfigService,
    private settingService: StoreSettingService,
    private routesService: RoutesService,
    private deviceService: DeviceService,
    private telegramService: TelegramService,
    private router: Router,
    private authService: AuthService,
  ) {
    setInterval(() => this.onResize(), 1000);
  }
  goLstPath() {
    setTimeout(() => this.router.navigate(['/']), 100);
    if (this.path() != '/') setTimeout(() => this.router.navigateByUrl(this.path()), 300);
  }
}
