import { Component, computed } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameChipSimpleComponent } from '@libs/stand-alone-components/casino/game-chip-simple/game-chip-simple';
import { StoreSettingService } from '@libs/app.services/store/store-setting.service';
import { GamesService } from '@libs/app.services/casino/casino-games.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { LuckyCellsService } from '@libs/app.services/landing/lucky-cells.service';
import { LuckyCellsState } from '@libs/interfaces';
@Component({
  selector: 'app-lucky-cells-game-overlay',
  imports: [CommonModule, GameChipSimpleComponent],
  templateUrl: './lucky-cells-game-overlay.component.html',
  styleUrl: './lucky-cells-game-overlay.component.scss',
})
export class LuckyCellsGameOverlayComponent {
  game_uuid = computed(() => this.service.recentLuckyCellGame()?.game.freespin?.game_uuid);
  game = toSignal(this.gamesService.fetchByExternal_id(this.game_uuid()));

  constructor(
    private settingService: StoreSettingService,
    private gamesService: GamesService,
    private service: LuckyCellsService,
  ) {}
  openGame() {
    this.settingService.setOverlayLuckyCells(false);
    if (this.service.state() === LuckyCellsState.FINISHED) {
      this.service.resetLuckyCellGame();
    }
  }
}
