import { Component, computed, effect, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LuckyCells, LuckyCellsGame, LuckyCellsState, LuckyCellsType } from '@libs/interfaces';
import { LuckyCellsGridCellFiatComponent } from '../../lucky-cells-grid/lucky-cells-grid-cell-fiat/lucky-cells-grid-cell-fiat.component';
import { LuckyCellsGridCellFreeSpinComponent } from '../../lucky-cells-grid/lucky-cells-grid-cell-free-spin/lucky-cells-grid-cell-free-spin.component';
import { LuckyCellsGridCellPointsComponent } from '../../lucky-cells-grid/lucky-cells-grid-cell-points/lucky-cells-grid-cell-points.component';
import { LuckyCellsGridCellRechargeComponent } from '../../lucky-cells-grid/lucky-cells-grid-cell-recharge/lucky-cells-grid-cell-recharge.component';
import { TranslateModule } from '@ngx-translate/core';
import { GameChipSimpleComponent } from '@libs/stand-alone-components/casino/game-chip-simple/game-chip-simple';
import { GamesService } from '@libs/app.services/casino/casino-games.service';
import { StoreSettingService } from '@libs/app.services/store/store-setting.service';
import { LuckyCellsService } from '@libs/app.services/landing/lucky-cells.service';
@Component({
  selector: 'app-lucky-cells-list-row',
  imports: [
    CommonModule,
    LuckyCellsGridCellFiatComponent,
    LuckyCellsGridCellPointsComponent,
    LuckyCellsGridCellRechargeComponent,
    LuckyCellsGridCellFreeSpinComponent,
    TranslateModule,
    GameChipSimpleComponent,
  ],
  templateUrl: './lucky-cells-list-row.component.html',
  styleUrl: './lucky-cells-list-row.component.scss',
})
export class LuckyCellsListRowComponent {
  game = input<LuckyCellsGame>();
  cell = computed<LuckyCells>(() => this.game()?.game as LuckyCells);
  isFreeSpin = computed<boolean>(() => this.cell()?.type === LuckyCellsType.FREE_SPIN_10);
  gameFs$;
  constructor(
    private gamesService: GamesService,
    private settingService: StoreSettingService,
    private luckyCellsService: LuckyCellsService,
  ) {
    effect(() => {
      if (this.game() && this.game().game?.freespin?.game_uuid && !this.gameFs$) {
        this.gameFs$ = this.gamesService.fetchByExternal_id(this.game().game?.freespin?.game_uuid);
      }
    });
  }
  openGame() {
    this.settingService.setOverlayLuckyCells(false);
    if (this.luckyCellsService.state() === LuckyCellsState.FINISHED) {
      this.luckyCellsService.resetLuckyCellGame();
    }
  }
}
