import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LuckyCellsService } from '@libs/app.services/landing/lucky-cells.service';
import { IconComponent } from '@libs/stand-alone-components/commons/icon/icon.component';
import { MillisecondsRemainingPipe } from '@libs/pipes/miliseconds.remaining.pipe';
import { StoreSettingService } from '@libs/app.services/store/store-setting.service';
import { LuckyCellsListComponent } from '../lucky-cells-list/lucky-cells-list.component';
import { LuckyCellsState } from '@libs/interfaces';
@Component({
  selector: 'app-lucky-cells-tab',
  imports: [CommonModule, TranslateModule, IconComponent, MillisecondsRemainingPipe, LuckyCellsListComponent],
  templateUrl: './lucky-cells-tab.component.html',
  styleUrl: './lucky-cells-tab.component.scss',
})
export class LuckyCellsTabComponent {
  showList = signal<boolean>(false);

  constructor(
    public service: LuckyCellsService,
    private settingService: StoreSettingService,
  ) {}
  close() {
    this.settingService.setOverlayLuckyCells(false);
    if (this.service.state() === LuckyCellsState.FINISHED) {
      this.service.resetLuckyCellGame();
    }
  }
  toggleList() {
    this.showList.update(v => !v);
  }
}
