import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LuckyCellsActionComponent } from './lucky-cells-action/lucky-cells-action.component';
import { LuckyCellsFooterComponent } from './lucky-cells-footer/lucky-cells-footer.component';
import { LuckyCellsGridComponent } from './lucky-cells-grid/lucky-cells-grid.component';
import { LuckyCellsTabComponent } from './lucky-cells-tab/lucky-cells-tab.component';
import { LuckyCellsInfoComponent } from './lucky-cells-info/lucky-cells-info.component';
import { StoreSettingService } from '@libs/app.services/store/store-setting.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { delay, tap } from 'rxjs';
import { AudioServiceLanding } from '@libs/app.services/audio.service-landing';
import { AUDIO } from '@libs/interfaces';
@Component({
  selector: 'app-lucky-cells',
  imports: [
    CommonModule,
    TranslateModule,
    LuckyCellsTabComponent,
    LuckyCellsInfoComponent,
    LuckyCellsActionComponent,
    LuckyCellsGridComponent,
    LuckyCellsFooterComponent,
  ],
  templateUrl: './lucky-cells.component.html',
  styleUrl: './lucky-cells.component.scss',
})
export class LuckyCellsComponent implements OnDestroy, OnInit {
  open = signal<boolean>(false);
  hideInfo = signal<boolean>(false);
  overlay = toSignal(
    this.settingService.selectOverlayLuckyCells().pipe(
      delay(100),
      tap(value => {
        this.open.set(value);
      }),
    ),
  );
  constructor(
    private readonly settingService: StoreSettingService,
    private readonly audioService: AudioServiceLanding,
  ) {}
  ngOnInit(): void {
    setTimeout(() => this.hideInfo.set(true), 5000);
  }
  ngOnDestroy(): void {
    setTimeout(() => this.audioService.pause(AUDIO.LUCKY_CELLS_APP), 1500);
  }
}
