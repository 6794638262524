import { AfterViewInit, Component, computed, effect, ElementRef, input, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AUDIO, LuckyCells, LuckyCellsState, LuckyCellsType } from '@libs/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { LuckyCellsService } from '@libs/app.services/landing/lucky-cells.service';
import { AudioServiceLanding } from '@libs/app.services/audio.service-landing';

@Component({
  selector: 'app-lucky-cells-grid-cell-fiat',
  imports: [CommonModule, TranslateModule],
  templateUrl: './lucky-cells-grid-cell-fiat.component.html',
  styleUrl: './lucky-cells-grid-cell-fiat.component.scss',
})
export class LuckyCellsGridCellFiatComponent implements AfterViewInit {
  isList = input<boolean>(false);
  cell = input.required<LuckyCells>();
  wrapper = viewChild<ElementRef>('wrapper');
  amount = computed(() => {
    if (this.cell().type === LuckyCellsType.FIAT_5) {
      return '5';
    }
    return '10';
  });
  base = viewChild<ElementRef>('base');
  animation = computed<boolean>(() => {
    if (this.isList()) {
      return false;
    }
    if (this.service.state() === LuckyCellsState.FINISHED) {
      const wiinner_cell_index = this.service.recentLuckyCellGame()?.game.index;
      return this.cell().index === wiinner_cell_index;
    }
    return false;
  });
  constructor(
    private service: LuckyCellsService,
    private audioService: AudioServiceLanding,
  ) {
    effect(() => {
      if (this.cell().state === 'show') {
        this.audioService.play(AUDIO.CLICK_SHORT);
        setTimeout(() => {
          this.audioService.pause(AUDIO.CLICK_SHORT);
        }, 100);
      }
      if (this.animation()) {
        this.audioService.play(AUDIO.PRIZE);
      }
      if (this.service.state() !== LuckyCellsState.FINISHED) {
        this.audioService.pause(AUDIO.PRIZE);
      }
    });
  }

  ngAfterViewInit(): void {
    this.wrapper().nativeElement.style.backgroundImage = `url("/assets/lucky-cells/cell-fiat.png")`;
    this.base().nativeElement.style.backgroundImage = `url("/assets/lucky-cells/cell-grey.png")`;
  }
}
