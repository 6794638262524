import { AUDIO, WAD_STATE } from '@libs/interfaces';

export const Audios = [
  {
    name: AUDIO.ACCEPTED,
    source: 'assets/sounds/accepted.mp3',
    apps: ['office', 'app'],
    volume: 1,
    state: WAD_STATE.STOPPED,
  },
  {
    name: AUDIO.TRASH,
    source: 'assets/sounds/trash.mp3',
    apps: ['office', 'app'],
    volume: 1,
    state: WAD_STATE.STOPPED,
  },
  {
    name: AUDIO.CHAT_RECEIVED,
    source: 'assets/sounds/ios_whatsapp_message.mp3',
    apps: ['office', 'app'],
    volume: 1,
    state: WAD_STATE.STOPPED,
  },
  {
    name: AUDIO.PENDING,
    source: 'assets/sounds/short-email.m4a',
    apps: ['office', 'app'],
    volume: 1,
    state: WAD_STATE.STOPPED,
  },
  {
    name: AUDIO.MESSAGE,
    source: 'assets/sounds/whatsapp_message.mp3',
    apps: ['office', 'app'],
    volume: 1,
    state: WAD_STATE.STOPPED,
  },
  {
    name: AUDIO.DRAW_EVALUATED,
    source: 'assets/sounds/SOULSURPLUS_finale_one_shot_mallet_Gbmaj7.wav',
    apps: ['office', 'app'],
    volume: 1,
    state: WAD_STATE.STOPPED,
  },
  {
    name: AUDIO.LUCKY_CELLS_APP,
    source: 'assets/sounds/lucky-cells-app.mp3',
    apps: ['landing'],
    volume: 1,
    state: WAD_STATE.STOPPED,
  },
  {
    name: AUDIO.CLICK_SHORT,
    source: 'assets/sounds/click-short.mp3',
    apps: ['landing'],
    volume: 1,
    state: WAD_STATE.STOPPED,
  },
  {
    name: AUDIO.PRIZE,
    source: 'assets/sounds/prize.mp3',
    apps: ['landing'],
    volume: 1,
    state: WAD_STATE.STOPPED,
  },
];
