<div class="wrapper">
  @if (service.state() === 'waiting') {
    <button class="waiting">
      {{ 'LUCKY_CELLS.ACTION.WAITING' | translate }}
    </button>
  }
  @if (service.state() === 'ready') {
    <p class="play" (click)="startGame()">
      {{ 'LUCKY_CELLS.ACTION.READY' | translate }}
    </p>
  } @else if (service.state() === 'playing') {
    <button class="spinning" (click)="pauseGame()">
      {{ 'LUCKY_CELLS.ACTION.PLAYING' | translate }}
    </button>
  } @else if (service.state() === 'finished') {
    <button class="prize" (click)="restartGame()">
      {{ 'LUCKY_CELLS.ACTION.FINISHED' | translate }}
    </button>
  }
</div>
@if (ramdomize$ | async) {}
@if (add$ | async) {}
