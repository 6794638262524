import { Injectable } from '@angular/core';
import { Audios } from '@libs/constants/sounds.constants';
import { AUDIO, IAudio, WAD_STATE } from '@libs/interfaces';
import Wad from 'web-audio-daw';

@Injectable({
  providedIn: 'root',
})
export class AudioServiceLanding {
  private readonly audios: IAudio[];
  constructor() {
    this.audios = Audios.filter(a => a.apps.indexOf('landing') > -1);
    this.audios.forEach(audio => (audio.wad = new Wad({ source: audio.source } as any)));
  }
  play(name: AUDIO, volume: number = null, loop = false): void {
    const audio = this.find(name);
    if (audio.state == WAD_STATE.PLAYING) return;
    const playVolume = volume || audio.volume;
    audio.wad.setVolume(playVolume);
    audio.wad.play({ loop });
    audio.state = WAD_STATE.PLAYING;
  }
  volume(name: AUDIO, volume: number): void {
    const audio = this.find(name);
    audio.wad.setVolume(volume);
  }
  pause(name: AUDIO): void {
    const audio = this.find(name);
    if (audio.state == WAD_STATE.STOPPED) return;
    audio.wad.stop();
    audio.state = WAD_STATE.STOPPED;
  }
  private find(name: AUDIO): IAudio {
    return this.audios.find(a => a.name === name);
  }
}
