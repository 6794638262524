<div class="wrapper">
  @if (showList()) {
    <div class="list">
      @for (game of service.luckyCellGames(); track game._id) {
        @if (game) {
          <app-lucky-cells-list-row [game]="game" />
        }
      } @empty {
        <app-no-data-info title="LUCKY_CELLS.LIST.TITLE" subtitle="LUCKY_CELLS.LIST.NO_DATA" />
      }
    </div>
  }
</div>
