import { Component, effect, input, resource, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LuckyCellsListRowComponent } from './lucky-cells-list-row/lucky-cells-list-row.component';
import { LuckyCellsService } from '@libs/app.services/landing/lucky-cells.service';
import { LuckyCellsGame } from '@libs/interfaces';
import { WalletService } from '@libs/app.services/wallet/wallet.service';
import { NoDataComponent } from '@libs/stand-alone-components/commons/no-data-info/no-data.component';
@Component({
  selector: 'app-lucky-cells-list',
  imports: [CommonModule, LuckyCellsListRowComponent, NoDataComponent],
  templateUrl: './lucky-cells-list.component.html',
  styleUrl: './lucky-cells-list.component.scss',
})
export class LuckyCellsListComponent {
  showList = input<boolean>(false);
  offset = signal<number>(0);
  limit = signal<number>(40);
  list = resource<LuckyCellsGame[], { offset: number; limit: number }>({
    request: () => ({ offset: this.offset(), limit: this.limit() }),
    loader: ({ request }) =>
      this.service.getLuckyCellGames(this.walletService.wallet().code, request.offset, request.limit),
  });
  constructor(
    public service: LuckyCellsService,
    public walletService: WalletService,
  ) {
    effect(() => {
      if (this.showList()) {
        this.list.reload();
      }
    });
  }
}
