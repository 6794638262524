<div class="wrapper">
  <div class="cell">
    @switch (cell().type) {
      @case ('fiat_5') {
        <app-lucky-cells-grid-cell-fiat [cell]="cell()" [isList]="true" />
      }
      @case ('points_10') {
        <app-lucky-cells-grid-cell-points [cell]="cell()" [isList]="true" />
      }
      @case ('points_15') {
        <app-lucky-cells-grid-cell-points [cell]="cell()" [isList]="true" />
      }
      @case ('points_20') {
        <app-lucky-cells-grid-cell-points [cell]="cell()" [isList]="true" />
      }
      @case ('bonus_recharge_50') {
        <app-lucky-cells-grid-cell-recharge [cell]="cell()" [isList]="true" />
      }
      @case ('free_spin_10') {
        <app-lucky-cells-grid-cell-free-spin [cell]="cell()" [isList]="true" />
      }
    }
  </div>
  <div class="info">
    @switch (cell().type) {
      @case ('fiat_5') {
        <p>{{ 'Premio de 5 reais gratis!!' | translate }}</p>
      }
      @case ('points_10') {
        <p>{{ 'Premio de 10 pontos!!' | translate }}</p>
      }
      @case ('points_15') {
        <p>{{ 'Premio de 15 pontos!!' | translate }}</p>
      }
      @case ('points_20') {
        <p>{{ 'Premio de 20 pontos!!' | translate }}</p>
      }
      @case ('bonus_recharge_50') {
        <p>{{ 'Bonus de recarga de 50 reais!!' | translate }}</p>
      }
      @case ('free_spin_10') {
        <p>{{ '10 free spins!!' | translate }}</p>
      }
    }
  </div>
  @if (gameFs$ | async; as gameFs) {
    <div class="game-fs" (click)="openGame()">
      <app-game-chip-simple [game]="gameFs" />
    </div>
  }
</div>
